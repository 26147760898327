import React, {
  useContext, useState, useEffect, createContext,
} from 'react';
import firebase from 'firebase/app';
import { useAuth } from './AuthContext';
import FullPageLoading from '../components/FullPageLoad';

const db = firebase.firestore();
const profileContext = createContext();
export const useProfile = () => useContext(profileContext);

const getAllOrgsForUser = async (organizations) => {
  const orgDocumentsRef = db.collection('organizations');
  const orgDocumentsP = organizations.map((orgUid) => orgDocumentsRef.doc(orgUid).get());
  try {
    const orgDocuments = await Promise.all(orgDocumentsP);
    return orgDocuments
      .map((doc) => doc.data())
      .reduce((acc, curr) => {
        acc[curr.organizationUid] = curr;
        return acc;
      }, {});
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
    console.log("Error while fetching a user's organizations.");
    /* eslint-enable no-console */
    return [];
  }
};

const getProfile = async (userUid) => {
  try {
    const userDocumentRef = db.collection('users').doc(userUid);
    const userDoc = await userDocumentRef.get();
    const {
      email, name, organizations: organizationUids, profileImage,
    } = userDoc.data();
    const organizations = await getAllOrgsForUser(organizationUids);
    const defaultOrg = Object.values(organizations).reduce((acc, curr) => {
      if (!acc || acc.organizationName > curr.organizationName) {
        return curr;
      }
      return acc;
    }, null);
    return {
      userProfile: {
        email, name, organizations, profileImage,
      },
      defaultOrg,
    };
  } catch (err) {
    /* eslint-disable no-console */
    console.warn('Error while fetching the user doc. Falling back on auth state for profile details.');
    console.log(err);
    /* eslint-enable no-console */
    return { userProfile: null, defaultOrg: null };
  }
};

export const useProvideProfile = (authState) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentOrg, setCurrentOrg] = useState(null);

  const changeCurrentOrg = (orgId) => {
    setCurrentOrg(orgId);
  };

  useEffect(() => {
    const fetchProfileDocument = async () => {
      try {
        const { userProfile, defaultOrg } = await getProfile(authState.currentAuthUser.uid);
        if (userProfile) {
          setProfile(userProfile);
        } else {
          setProfile({
            email: authState.currentAuthUser.email,
            name: authState.currentAuthUser.name,
            organizations: {},
            profileImage: authState.currentAuthUser.profileImage,
          });
        }
        setCurrentOrg(defaultOrg?.organizationUid);
        setLoading(false);
      } catch (error) {
        /* eslint-disable no-console */
        console.log('Error fetching user profile.');
        console.log(error);
        /* eslint-enable no-console */
        setLoading(false);
      }
    };
    if (authState.currentAuthUser) {
      fetchProfileDocument();
    }
  }, [authState]);

  return {
    profile,
    loading,
    setLoading,
    currentOrg,
    changeCurrentOrg,
  };
};

export const ProvideProfile = ({ children }) => {
  const auth = useAuth();
  const profile = useProvideProfile(auth.authState);
  if (auth.authState.initializing || profile.loading) return (<FullPageLoading open />);
  return (
    <profileContext.Provider value={profile}>
      {children}
    </profileContext.Provider>
  );
};
