import React from 'react';
import {
  Redirect, BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import PrivateRoute from './PrivateRoute';
import { useAuth } from '../context/AuthContext';

export default function Routes({ children, basePath }) {
  const auth = useAuth();
  if (auth.authState.initalizing) return (<div>Loading...</div>);
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={basePath} />} />
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path={basePath}>
          {children}
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
