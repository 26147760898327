import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from './AppBar/AppBar';
import SideBar from './SideBar';
import Constants from '../constants';

const { styling } = Constants;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentOpen: {
    marginLeft: theme.spacing(styling.drawers.drawerOpenSpacing),
  },
  contentClosed: {
    marginLeft: theme.spacing(styling.drawers.drawerClosedSpacing),
  },
  toolbar: theme.mixins.toolbar,
}));

export default function MainNavigation({ profile, children }) {
  const classes = useStyles();

  const [navOpen, setNavOpen] = useState(false);

  const handleDrawerOpen = () => {
    setNavOpen(true);
  };

  const handleDrawerClose = () => {
    setNavOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        handleDrawerOpen={handleDrawerOpen}
        open={navOpen}
        profile={profile}
      />
      <SideBar
        open={navOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <div className={classes.toolbar} />
      <div
        className={clsx(classes.content, {
          [classes.contentOpen]: navOpen,
          [classes.contentClosed]: !navOpen,
        })}
      >
        {children}
      </div>
    </>

  );
}
