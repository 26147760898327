import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Constants from '../../constants';
import LLlogo from '../../leverage-lab-logo-white-web-sans-text.png';
import LLLightbulb from '../../LL-lightbulb-icon-white.png';
import ProfileDetailsPopover from './ProfileDetailsPopover';
import OrgSelect from './OrgSelect';

const { styling } = Constants;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: '20%',
    width: '80%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(styling.drawers.drawerOpenSpacing),
      width: `calc(100% - ${theme.spacing(styling.drawers.drawerOpenSpacing)}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  logo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: theme.spacing(1, 2, 1, 2),
    resizeMode: 'contain',
  },
  title: {
    flexGrow: 1,
  },
  subText: {
    marginRight: theme.spacing(5),
    color: theme.palette.primary.contrastText,
  },
  lightBulbIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    resizeMode: 'contain',
  },
  orgSelect: {
    paddingRight: theme.spacing(4),
  },
}));

export default function DataLabAppBar({
  profile, handleDrawerOpen, open,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [orgSelectOpen, setOrgSelectOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleOrgOpen = () => {
    setOrgSelectOpen(!orgSelectOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popOpen = Boolean(anchorEl);

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <OrgSelect open={orgSelectOpen} toggleOrgOpen={toggleOrgOpen} />
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <img src={LLlogo} className={classes.logo} alt="Leverage Lab" />
        <Typography variant="h6" className={classes.title} noWrap>
          Data Lab
        </Typography>
        <Typography />
        <Tooltip title="Learn More">
          <IconButton href="http://leveragelab.com">
            <img src={LLLightbulb} className={classes.lightBulbIcon} alt="Leverage Lab" />
          </IconButton>
        </Tooltip>
        <Typography className={classes.subText} noWrap>
          Powered by Leverage Lab
        </Typography>
        <Avatar src={profile.profileImage} onClick={handleClick} variant="square" />
        <ProfileDetailsPopover
          profile={profile}
          open={popOpen}
          handleClose={handleClose}
          anchorEl={anchorEl}
          toggleOrgOpen={toggleOrgOpen}
        />
      </Toolbar>
    </AppBar>
  );
}
