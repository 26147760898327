import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useProfile } from '../context/ProfileContext';
import MainNavigation from '../components/MainNavigation';
import AppBar from '../components/AppBar/AppBar';
import Dashboard from '../components/Dashboard';
import { usePages } from '../context/PagesContext';

const useStyles = makeStyles((theme) => ({
  errorMessageContainer: {
    margin: theme.spacing(2),
  },
  welcomeError: {
    padding: theme.spacing(1),
  },
  errorSubtext: {
    paddingLeft: theme.spacing(1),
  },
}));

export default function MainContent() {
  const classes = useStyles();
  const { profile } = useProfile();
  const { orgPages, currentPage } = usePages();
  const currentPageObj = orgPages[currentPage];

  if (!profile?.organizations || Object.keys(profile.organizations).length === 0) {
    return (
      <div>
        <AppBar profile={profile} />
        <div className={classes.errorMessageContainer}>
          <Typography variant="h4" className={classes.welcomeError}>
            Welcome
            {' '}
            {profile.name}
            .
          </Typography>
          <Typography className={classes.errorSubtext}>
            You have not been added to an organizations.
            {' '}
            Please contact your administrator to gain access.
          </Typography>
        </div>
      </div>
    );
  }
  return (
    <div>
      <MainNavigation profile={profile}>
        <Dashboard currentPageObj={currentPageObj} />
      </MainNavigation>
    </div>
  );
}
