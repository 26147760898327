import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@material-ui/core';
import { usePages } from '../context/PagesContext';

const useStyles = makeStyles((theme) => ({
  menuText: {
    flex: 1,
    flexWrap: 'wrap',
  },
  menuIcon: {
    minWidth: '50px',
  },
  list: {
    flexGrow: 1,
  },
  inset: {
    marginLeft: theme.spacing(2),
  },
}));

export default function PageMenuItem({
  open, page, highlight, handleSelectedListItem,
}) {
  const classes = useStyles();
  const { changeCurrentPage } = usePages();
  const expandable = page.children && page.children.length > 0;
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleOpenSubMenu = () => {
    setOpenCollapse(!openCollapse);
  };

  const ParentMenuItem = (
    <ListItem
      dense={page.depth > 1}
      button
      selected={highlight === page.pageUid}
      onClick={() => {
        changeCurrentPage(page.pageUid);
        handleSelectedListItem(page.pageUid);
      }}
      key={`nav-item-${page.pageUid}`}
    >
      { page.iconName
        ? (
          <>
            <ListItemIcon className={classes.menuIcon}>
              <Icon>{page.iconName}</Icon>
            </ListItemIcon>
            <ListItemText
              component={Typography}
              className={classes.menuText}
              primary={page.pageName}
            />
          </>
        ) : (
          <ListItemText
            inset
            className={page.depth > 1 ? classes.inset : classes.menuText}
            secondary={page.pageName}
          />
        )}
      { expandable && openCollapse === true && <ExpandLessIcon onClick={handleOpenSubMenu} /> }
      { expandable && openCollapse === false && <ExpandMoreIcon onClick={handleOpenSubMenu} /> }
    </ListItem>
  );

  const ChildMenuItem = expandable ? (
    <Collapse in={openCollapse && open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {page.children.map((sub) => (
          <PageMenuItem
            page={sub}
            open={open}
            highlight={highlight}
            key={sub.pageUid}
            handleSelectedListItem={handleSelectedListItem}
          />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <React.Fragment key={`nav-div-${page.pageUid}`}>
      {ParentMenuItem}
      {ChildMenuItem}
    </React.Fragment>
  );
}
