import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import Constants from '../constants';
import { usePages } from '../context/PagesContext';
import PageMenu from './PageMenu';

const { styling } = Constants;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.spacing(styling.drawers.drawerClosedSpacing),
    flexShrink: 0,
  },
  drawerOpen: {
    width: '20%',
    overflowX: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(styling.drawers.drawerOpenSpacing),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      whiteSpace: 'nowrap',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(styling.drawers.drawerClosedSpacing),
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 1, 1),
    ...theme.mixins.toolbar,
  },
}));

export default function SideDrawer({ open, handleDrawerClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const { pages, currentPage } = usePages();
  const [highlight, setHighlight] = useState(currentPage);

  const handleSelectedListItem = (pageId) => {
    setHighlight(pageId);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {
          pages && pages.length > 0
            ? (pages.map((page) => (
              <PageMenu
                open={open}
                highlight={highlight}
                handleSelectedListItem={handleSelectedListItem}
                page={page}
                key={page.pageUid}
              />
            ))
            )
            : (
              <ListItem button>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            )
        }
      </List>
    </Drawer>
  );
}
