import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line no-undef
const appConfig = APP_CONFIG;

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
  dashboardIframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
}));

export default function Dashboard({ currentPageObj }) {
  const pageVisuals = currentPageObj?.visualizations[0];
  const classes = useStyles();
  const { qlikConfig } = appConfig;

  if (!pageVisuals) {
    return <div />;
  }
  return (
    <div className={classes.root}>
      {
          currentPageObj?.visualizations && currentPageObj.visualizations.length > 0
            ? (
              <iframe
                title={pageVisuals.dashboardName}
                className={classes.dashboardIframe}
                src={`https://${qlikConfig.domain}/single/?appid=${pageVisuals.qlikAppUid}&sheet=${pageVisuals.dashboardUid}&opt=ctxmenu,currsel`}
              />
            )
            : (
              <div>
                <h1>
                  {`${currentPageObj?.pageName} does not currently exist for your organization`}
                </h1>
              </div>
            )
      }
    </div>
  );
}
