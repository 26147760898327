import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const palleteTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#17293a',
    },
    secondary: {
      main: '#3f9dc2',
    },
  },
});
const theme = responsiveFontSizes(palleteTheme);

export default theme;
