import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import DefaultRoutes from './containers/DefaultRoutes';
import { ProvideAuth } from './context/AuthContext';
import { ProvideProfile } from './context/ProfileContext';
import { ProvidePages } from './context/PagesContext';
import MainContent from './containers/MainContent';
import theme from './theme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ProvideAuth>
          <Router>
            <DefaultRoutes basePath="/datalab">
              <ProvideProfile>
                <ProvidePages>
                  <MainContent />
                </ProvidePages>
              </ProvideProfile>
            </DefaultRoutes>
          </Router>
        </ProvideAuth>
      </ThemeProvider>
    </div>
  );
}

export default App;
