import React, {
  useContext, useState, useEffect, createContext,
} from 'react';
import firebase from 'firebase/app';
import FullPageLoading from '../components/FullPageLoad';
import { useProfile } from './ProfileContext';

const db = firebase.firestore();
const pagesContext = createContext();
export const usePages = () => useContext(pagesContext);

const makePagesTree = (pagesDocsList) => {
  const pagesUids = pagesDocsList.reduce((acc, page, curr) => {
    acc[page.pageUid] = curr;
    return acc;
  }, {});

  const pagesTree = [];
  let parentPage;
  pagesDocsList.forEach((page) => {
    if (page.parent === null) {
      parentPage = page;
      pagesTree.push(parentPage);
      return;
    }
    const parentPages = pagesDocsList[pagesUids[page.parent]];
    parentPages.children = [...(parentPages.children || []), page];
  });
  return {
    pagesTree,
  };
};

const getAllPagesForOrg = async (currentOrgUid) => {
  try {
    const pagesDocsP = await db.collection('organizations').doc(currentOrgUid).collection('pages').get();
    const pagesList = pagesDocsP.docs.map((doc) => doc.data());
    const pagesDocsList = pagesList.sort((a, b) => a.pageNumber - b.pageNumber);
    const pagesDocs = pagesDocsList
      .reduce((acc, curr) => {
        acc[curr.pageUid] = curr;
        return acc;
      }, {});
    return {
      pagesDocsList, pagesDocs,
    };
  } catch (err) {
    console.error(err, "Error while fetching a organizations's pages.");
    return { pagesDocList: null, pagesDocs: null };
  }
};

const getPages = async (currentOrg) => {
  try {
    const { pagesDocsList, pagesDocs } = await getAllPagesForOrg(currentOrg);
    const { pagesTree } = makePagesTree(pagesDocsList);
    const defaultPage = Object.values(pagesDocs).reduce((acc, curr) => {
      if (!acc || acc.pageNumber > curr.pageNumber) {
        return curr;
      }
      return acc;
    }, null);
    return {
      pagesTree, defaultPage, pagesDocs,
    };
  } catch (err) {
    console.warn('Error while fetching the org doc.');
    console.error(err);
    return { pagesTree: null, defaultPage: null, pagesDocs: null };
  }
};

export const useProvidePages = (profile) => {
  const [pages, setPages] = useState(null);
  const [orgPages, setOrgPages] = useState(null);
  const [priming, setPriming] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);

  const changeCurrentPage = (pageUid) => {
    setCurrentPage(pageUid);
  };

  useEffect(() => {
    const fetchPagesDocuments = async () => {
      try {
        const { pagesTree, defaultPage, pagesDocs } = await getPages(profile.currentOrg);
        if (pagesTree && pagesDocs) {
          setPages(pagesTree);
          setOrgPages(pagesDocs);
        } else {
          setPages([]);
          setOrgPages({});
        }
        setCurrentPage(defaultPage?.pageUid);
        setPriming(false);
      } catch (error) {
        console.error(error, 'Error fetching organizations documents.');
        setPriming(false);
      }
    };
    if (!profile.loading && profile.currentOrg) {
      fetchPagesDocuments();
    }
  }, [profile]);

  return {
    orgPages,
    pages,
    priming,
    currentPage,
    changeCurrentPage,
  };
};

export const ProvidePages = ({ children }) => {
  const profile = useProfile();
  const pages = useProvidePages(profile);
  if (profile.loading || pages.priming) return (<FullPageLoading open />);
  return (
    <pagesContext.Provider value={pages}>
      {children}
    </pagesContext.Provider>
  );
};
