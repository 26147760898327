// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

// const firebaseConfig = require("./firebaseConfig.json")
// eslint-disable-next-line no-undef
const firebaseConfig = FIREBASE_CONFIG;

firebase.initializeApp(firebaseConfig);

if (window.location.hostname === 'localhost') {
  // eslint-disable-next-line no-console
  console.log('Using Emulator');
  firebase.functions().useEmulator('localhost', 5001);
}
