import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import googleSignInBtnImage from '../btn_google_signin_dark_normal_web.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://leveragelab.com/wp-content/uploads/2019/08/leverage-lab-header-background-lab-2.png?id=2530)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  welcomeText: {
    color: '#162a3a',
  },
  welcomeSubText: {
    fontStyle: 'italic',
    paddingTop: theme.spacing(4),
  },
  welcomeSubTextLink: {
    textDecoration: 'none',
  },
  logoPaper: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
  },
  logoImage: {
    backgroundColor: 'transparent',
    padding: '3%',
    height: '60px',
  },
  adminSignInText: {
    fontStyle: 'italic',
    margin: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  if (auth.authState.currentAuthUser) return (<Redirect to="/" />);

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={9} className={classes.image} />
      <Grid item xs={12} sm={8} md={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography variant="h2" className={classes.welcomeText}>Welcome to Data Lab</Typography>

          <Typography variant="subtitle1" className={classes.welcomeSubText}>
            Powered by
            {' '}
            <a href="https://leveragelab.com/" rel="noreferrer" target="_blank" className={classes.welcomeSubTextLink}>Leverage Lab</a>
          </Typography>
          <Button
            type="submit"
            className={classes.submit}
            onClick={auth.googleSignIn}
          >
            <img src={googleSignInBtnImage} alt="Google Signin Button" />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
