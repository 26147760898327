import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { useAuth } from '../../context/AuthContext';
import { useProfile } from '../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    padding: theme.spacing(2),
    minWidth: theme.spacing(45),
  },
  profileName: {
    paddingBottom: theme.spacing(2),
  },
  profileEmail: {
    paddingBottom: theme.spacing(1),
  },
  signOutButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

export default function ProfileDetailsPopover({
  open, anchorEl, handleClose, toggleOrgOpen,
}) {
  const { profile, currentOrg } = useProfile();
  const { googleSignOut } = useAuth();
  const classes = useStyles();
  const id = open ? 'profile-popover' : undefined;
  const handleOrgOpen = (e) => {
    e.preventDefault();
    toggleOrgOpen();
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.popoverContent}>
        <Typography variant="h6" className={classes.profileName}>
          {profile.name}
        </Typography>
        <Typography className={classes.profileEmail}>
          {profile.email}
        </Typography>

        <Typography>
          Current Organization:
          {' '}
          {profile.organizations[currentOrg]?.organizationName}
        </Typography>

        <Typography variant="subtitle1">
          <Link href={null} onClick={handleOrgOpen}>
            Change
          </Link>
        </Typography>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.signOutButton}
          onClick={googleSignOut}
        >
          Sign Out
        </Button>
      </div>
    </Popover>
  );
}
