import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogActions from '@material-ui/core/DialogActions';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useProfile } from '../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  checkAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  check: {
    color: theme.palette.success.main,
  },
}));

export default function OrgSelect({ open, toggleOrgOpen }) {
  const classes = useStyles();
  const { profile, currentOrg, changeCurrentOrg } = useProfile();
  const [selectedOrg, setSelectedOrg] = useState(currentOrg);

  const handleCancel = () => {
    toggleOrgOpen();
  };

  const handleSubmit = () => {
    changeCurrentOrg(selectedOrg);
    toggleOrgOpen();
  };

  const handleOrgSelect = (uid) => {
    setSelectedOrg(uid);
  };
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select Organization</DialogTitle>
      <List>
        {Object.values(profile.organizations).map((org) => {
          const checkbox = (uid) => {
            if (uid !== selectedOrg) {
              return (
                <Avatar className={classes.checkAvatar}>
                  <ApartmentIcon />
                </Avatar>
              );
            }

            return (
              <Avatar className={classes.checkAvatar}>
                <CheckIcon key={`check-${uid}`} className={classes.check} />
              </Avatar>
            );
          };
          return (
            <React.Fragment key={`frag-${org.organizationUid}`}>

              <ListItem
                button
                key={`list-item-${org.organizationUid}`}
                onClick={() => handleOrgSelect(org.organizationUid)}
              >
                {checkbox(org.organizationUid)}
                {org.organizationName}
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>

  );
}
