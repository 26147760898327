import React, {
  useContext, useState, useEffect, createContext,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import appConfig from '../appConfig';

const authContext = createContext();

export const useAuth = () => useContext(authContext);
export const fbAuth = firebase.auth();
export const db = firebase.firestore();

export const useProvideAuth = () => {
  const [authState, setAuthState] = useState({
    initalizing: true,
    currentAuthUser: null,
  });

  const { qlikConfig } = appConfig;

  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const googleSignIn = () => {
    if (authState.currentAuthUser) return;
    fbAuth.signInWithRedirect(googleProvider);
  };

  const googleSignOut = () => {
    fbAuth.signOut();
    sessionStorage.removeItem('qlik.token');
  };

  const signInWithQlik = async () => {
    const getUserDetails = () => fetch(`https://${qlikConfig.domain}/api/v1/users/me`, {
      credentials: 'include',
      headers: { 'qlik-web-integration-id': qlikConfig.webIntegrationId },
    });
    let storedQlikSession = sessionStorage.getItem('qlik.token');
    let parsedQlikToken;
    if (!storedQlikSession || JSON.parse(storedQlikSession).expiresAt < (Date.now() / 1000)) {
      const authQlikResp = await firebase.functions().httpsCallable('authQlik')();
      if (!authQlikResp.data?.token) throw new Error('No token found in response.');
      parsedQlikToken = authQlikResp.data;
      storedQlikSession = null;
    } else {
      parsedQlikToken = JSON.parse(storedQlikSession);
    }
    let getQlikUserResp = await getUserDetails();
    if (getQlikUserResp.status === 401) {
      await fetch(
        `https://${qlikConfig.domain}/login/jwt-session?qlik-web-integration-id=${qlikConfig.webIntegrationId}`,
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${parsedQlikToken.token}`,
            'Qlik-Web-Integration-ID': qlikConfig.webIntegrationId,
          },
        },
      );
      getQlikUserResp = await getUserDetails();
      if (getQlikUserResp.status > 400) throw new Error('User could not sign into qlik.');
    }
    if (!storedQlikSession) {
      sessionStorage.setItem('qlik.token', JSON.stringify(parsedQlikToken));
    }
  };

  useEffect(() => {
    const unsubscribe = fbAuth.onAuthStateChanged((user) => {
      if (user) {
        const authUser = {
          uid: user.uid, email: user.email, profileImage: user.photoURL, name: user.displayName,
        };
        signInWithQlik()
          .then(() => setAuthState({ initalizing: false, currentAuthUser: authUser }))
          .catch((e) => {
            console.error(e);
            googleSignOut();
          });
      } else {
        setAuthState({ initalizing: false, currentAuthUser: null });
      }
    });
    return () => unsubscribe();
  }, []);

  return {
    authState,
    googleSignIn,
    googleSignOut,
    fbAuth,
  };
};

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
};
